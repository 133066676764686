.adminDashboardSideBar_container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 300px;
  height: 100vh;
  overflow-y: scroll;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.adminDashboardSideBar_logo {
  display: flex;
  width: 200px;
  height: 60px;
  align-self: flex-start;
  cursor: pointer;
}

.adminDashboardSideBar_subContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100vh;
  justify-content: space-between;
}

.adminDashboardSideBar_menuItemContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.adminDashboardSideBar_menuItem,
.adminDashboardSideBar_activeMenuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.adminDashboardSideBar_activeMenuItem {
  border: 1px solid var(--darkSlateGray);
  border-radius: 4px;
}

.adminDashboardSideBar_menuItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1.05);
  border-radius: 4px;
}

.adminDashboardSideBar_menuItemTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  transition: color 0.3s ease;
}

.adminDashboardSideBar_menuIcon {
  width: 32px;
  height: 32px;
  transition: transform 0.3s ease;
}

.adminDashboardSideBar_menuItem:hover .adminDashboardSideBar_menuIcon {
  transform: scale(1.2);
}

.adminDashboardSideBar_menuItem:hover .adminDashboardSideBar_menuItemTitle {
  color: var(--darkSlateGray);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .adminDashboardSideBar_container {
    width: 240px;
    gap: 30px;
  }

  .adminDashboardSideBar_logo {
    width: 160px;
    height: 50px;
  }

  .adminDashboardSideBar_menuItemTitle {
    font-size: 18px;
  }

  .adminDashboardSideBar_menuIcon {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 480px) {
  .adminDashboardSideBar_container {
    display: none;
  }
}
