.adminDashboardHeader_container {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px;
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  z-index: 2;
}

.adminDashboardHeader_heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.menuIcon {
  display: none !important;
}

.drawerContent {
  width: 250px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
}

.drawerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #ddd;
}

.closeIcon {
  color: var(--primaryColor);
}

.drawerLogoutButton {
  width: 100%;
  margin-top: auto;
  background-color: var(--errorColor);
  color: #fff;
  font-size: 16px;
}

.adminDashboardHeader_popoverContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}

.adminDashboardHeader_name {
  font-size: 20px;
  font-weight: 300;
}

.adminDashboardSideBar_logo {
  display: none;
}

@media (max-width: 480px) {
  .adminDashboardSideBar_logo {
    display: flex;
    width: 100px;
    height: 50px;
  }
  .adminDashboardHeader_heading {
    display: none;
  }
  .adminDashboardHeader_profileAndMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .menuIcon {
    display: block !important;
  }

  .adminDashboardHeader_heading {
    font-size: 18px;
  }
}
