.imageContainerStyle {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
