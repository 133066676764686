.adminLayout_container {
  display: flex;
  width: 100%;
  gap: 2px;
  background-color: var(--white);
}

.adminLayout_subContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  background-color: var(--white);
}
